@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap')

body
  font-family: "Josefin Sans", sans-serif
  background-color: #f2f2f2
  color: #130307
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  height: 100vh
  width: 100vw
  display: flex
  justify-content: center
  align-items: center